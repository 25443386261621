/*******************
 Topbar
*******************/
.mat-toolbar {
  background: url('../../../assets/images/background/keycloak-bg.png') no-repeat center !important;
  background-size: cover !important;
}
mat-toolbar {
    box-shadow: 1px 0 10px rgb(0, 0, 0);
    z-index: 10;
    &.topbar {
      padding: 0px 15px;
    }
    .navbar-header {
      text-align: center;
      .navbar-brand {
        display: flex;
        line-height: 18px;
        align-items: center;
        .dark-logo {
          display: none;
        }
        b {
          margin-right: 5px;
        }
      }
    }

    .profile-pic {
      width: 40px !important;
      height: 40px !important;
      border-radius: 100%;
    }
  }
  /*******************
   Topbar
  *******************/
  .topbar {
    position: relative;
    z-index: 2;
  }
  .watchdog-sidenav-container {
    flex: 1 0 auto;
  }
