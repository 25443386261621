/* You can add global styles to this file, and also import other style files */

/* Add application styles & imports to this file! */
@import '~jsoneditor/dist/jsoneditor.min.css';

div.jsoneditor-mode-code{
  height:600px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-mdc-table .mdc-data-table__row:hover{
  background-color: rgba(167, 197, 220, 0.08) !important;
  cursor: pointer;
}
.hg-grid-align-left {
  position: absolute;
  left: 1px;
}
.attributes-list span {
  font-weight: bold;
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
$error_color: #fb8d5a;
$success_color: #1b9b1b;
.Offline, .send_false, .deleted_inactive, .active_false{
  color: $error_color !important;
}
.Online, .send_true, .deleted_active, .active_true, .success{
  color: $success_color !important;
}
.error {
  color: #b60000  !important;
}
.file-input {
  display: none;
}
.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  width: 100%;
  cursor: pointer;
  border: 1px dashed #ccc;
  border-radius: 5px;
}
.upload-btn {
  margin: 1rem;
}
.upload-spinner {
  max-height: 2rem;
}
