/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
Main sidebar
******************/
.mdc-list {
  padding-top: 20px !important;
}

mat-nav-list .mdc-list-item {
  padding: 0 !important;
}

mat-sidenav {
  //width: 240px;
  padding-top: 0;
}

#snav {
  box-shadow: 1px 0 15px rgba(0, 0, 0, 0.27);

  .mat-mdc-list-item {
    &::before {
      display: none;
    }

    &.selected > .mdc-list-item__content > a {
      background: $themecolor-alt;
      border-radius: $radius;
      color: $white;

      mat-icon {
        color: $white;
      }

      .dd-icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }

    &.selected .sub-item .mdc-list-item__content a.selected {
      background: transparent;
      color: $inverse;
      font-weight: 500;
    }

    .mdc-list-item__content {
      display: block;
      height: 45px;

      &:hover {
        background: none;

        > a {
          color: $themecolor;
        }
      }
    }

    a {
      height: 45px;
      padding: 0 20px 0 10px;
      align-items: center;
      display: flex;
      font-size: 14px;
      white-space: nowrap;
      color: $dark;

      mat-icon:not(.dd-icon) {
        margin-right: 8px;
      }

      mat-icon {
        color: $sidebar-icons;
      }

      .dd-icon {
        font-size: 16px;
        width: 16px;
        transition: 0.2s ease-in;
        margin: 5px 0 5px 5px;
        height: 16px;
      }
    }
  }

  .mat-mdc-list-item.selected .mdc-list-item__content a {
    background: url("../../images/background/keycloak-bg.png");

    border-radius: $radius;
    color: $white;

    mat-icon {
      color: $white;
    }

    .dd-icon {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
}
.mini_menu {
  color: $white ;
}
.sidebar-closed #snav {
  &.mat-sidenav ~ .mat-drawer-content {
    margin-left: 0px !important;
  }
}
